import { Functions } from "./functions";
import * as methods from "./methods";
import { moveLockBackgroundImage } from "./images/locks/movelock/movelock";
import { clickSound } from "./audio/click";

export default class Locks extends Functions {
  containerId: HTMLElement["id"] = "LockContainer";

  canvasId: HTMLCanvasElement["id"] = "LockCanvas";

  buttons: { [key: string]: Button } = {};

  image = new Image();

  correctKey!: string;
  userKey = "";

  async run() {
    await this.waitForImage(this.image);
    this.canvas = document.createElement("canvas");
    this.width = this.image.naturalWidth;
    this.height = this.image.naturalHeight;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.canvas.id = this.canvasId;
    this.canvas.dataset.buttons = JSON.stringify(this.buttons);

    const canvasContainer = document.getElementById(this.containerId);
    if (canvasContainer == undefined) return;
    canvasContainer.appendChild(this.canvas);
    this.stage = this.canvas.getContext("2d") as CanvasRenderingContext2D;
    this.#drawLock();
    this.canvas.addEventListener("click", (e: MouseEvent) =>
      this.#onMouseDown(e)
    );
  }

  /** @ignore */
  #drawLock() {
    this.stage.drawImage(this.image, 0, 0, this.width, this.height);
  }

  /** @ignore */
  #drawButtons() {
    for (const id in this.buttons) {
      this.stage.save();
      const button = this.buttons[id];
      if (!button.image) return;
      const x = button.leftTop.x * this.width;
      const y = button.leftTop.y * this.height;
      const width = button.width * this.width;
      const height = button.height * this.height;
      this.stage.drawImage(button.image, x, y, width, height);
    }
  }

  /** @ignore */
  #onMouseDown(e: MouseEvent) {
    this.mouseEvent = e;
    console.log("x:" + this.mouse.x + ", " + "y:" + this.mouse.y);
    this.#detectButton();
  }

  /** @ignore */
  #detectButton() {
    const x = this.mouse.x;
    const y = this.mouse.y;
    for (const id in this.buttons) {
      const button = this.buttons[id];
      if (
        x >= button.leftTop.x &&
        x <= button.rightBottom.x &&
        y >= button.leftTop.y &&
        y <= button.rightBottom.y
      ) {
        console.log("Button: " + id + " detected");
        this.checkInput(button.input);
        button.audioPlayer.play()
        return true;
      }
    }
    console.log("check for button");
    return false;
  }

  checkInput(input: Button["input"]) {
    this.userKey += input;
    if (this.userKey.length > this.correctKey.length)
      this.userKey = this.userKey.substring(
        this.userKey.length - this.correctKey.length
      );
    if (this.userKey == this.correctKey) this.onSuccess();
    console.log(this.userKey);
  }
}

export class Button {
  input!: string;
  src!: string;
  image?: HTMLImageElement;
  audioPlayer: HTMLAudioElement = new Audio(clickSound)
  leftTop!: {
    x: number;
    y: number;
  };
  rightBottom!: {
    x: number;
    y: number;
  };
  constructor(button: methods.Button) {
    this.input = button.input;
    this.leftTop = button.leftTop;
    this.rightBottom = button.rightBottom
    if (button.soundEffect) this.audioPlayer.src = button.soundEffect;
  }

  get width() {
    return this.rightBottom.x - this.leftTop.x;
  }

  get height() {
    return this.rightBottom.y - this.leftTop.y;
  }
}

export class MoveLock extends Locks {
  constructor() {
    super();
    this.image.src = moveLockBackgroundImage;
    this.buttons["up"] = new Button({
      input: "u",
      leftTop: { x: 0.3013392857142857, y: 0.3896276595744681 },
      rightBottom: { x: 0.71875, y: 0.5678191489361702 },
    });
    this.buttons["left"] = new Button({
      input: "l",
      leftTop: { x: 0.078125, y: 0.6329787234042553 },
      rightBottom: { x: 0.24107142857142858, y: 0.7433510638297872 },
    });
    this.buttons["right"] = new Button({
      input: "r",
      leftTop: { x: 0.7790178571428571, y: 0.6303191489361702 },
      rightBottom: { x: 0.921875, y: 0.7460106382978723 },
    });
    this.buttons["down"] = new Button({
      input: "d",
      leftTop: { x: 0.4017857142857143, y: 0.8550531914893617 },
      rightBottom: { x: 0.5959821428571429, y: 0.9388297872340425 },
    });
  }
}
