import { Functions } from "./functions";

export default class extends Functions {
  audioLanguage = "en-GB";
  subtitleLanguage = "en-GB";
  fps = 30;
  videoInterval!: number;
  controls = false;
  controlsTimeout = 2000;

  /** @ignore */
  #lastMouseMove = Date.now();
  /** @ignore  */
  #currentScene!: Scene;


  /** @ignore */
  #poster!: HTMLImageElement;
  /** @ignore */
  #initialSceneId!: string;

  scenes: { [key: string]: Scene } = {};
  buttons: { [key: string]: Button } = {};

  constructor() {
    super();
    this.canvas = document.createElement("canvas");
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.canvas.id = this.canvasId;

    const canvasContainer = document.getElementById(this.containerId);
    if (canvasContainer == undefined) return;
    canvasContainer.appendChild(this.canvas);
    this.stage = this.canvas.getContext("2d") as CanvasRenderingContext2D;
    this.canvas.addEventListener("click", (e: MouseEvent) =>
      this.#onMouseDown(e)
    );
  }

  set poster(src: HTMLImageElement["src"]) {
    this.#poster = new Image();
    this.#poster.src = src;
    this.#poster.addEventListener("load", () => {
      this.stage.drawImage(this.#poster, 0, 0, this.width, this.height);
    });
  }



  set initialSceneId(id: string) {
    /* if (!this.scenes[id]) {
      console.log("Scene not found, create scene first");
      return;
    } */
    this.#initialSceneId = id;
  }

  get initialSceneId() {
    return this.#initialSceneId;
  }

  get currentScene() {
    return this.#currentScene;
  }

  addScene(id: string, src: HTMLVideoElement["src"]) {
    this.scenes[id] = new Scene(src);
    this.scenes[id].id = id;
  }

  playScene(scene: Scene) {
    console.log("play");
    this.#currentScene.video.pause();
    this.#currentScene.video.currentTime = 0;
    this.#currentScene = scene;
    this.#currentScene.video = scene.video;
    this.#drawVideo();
    this.#currentScene.video.play();
    this.canvas.dataset.videoSrc = this.#currentScene.video.src;
    this.canvas.dataset.loopFrom = this.#currentScene.loopFrom.toString();
    this.canvas.dataset.clickMaps = JSON.stringify(
      this.#currentScene.clickMaps
    );
    this.#addListeners();
  }

  /** @ignore */
  #addListeners() {
    this.#currentScene.video.addEventListener("ended", () => {
      this.#onEnded();
    });
    this.#currentScene.video.addEventListener("play", () => {
      this.#onPlay();
    });
    this.#currentScene.video.addEventListener("pause", () => {
      this.#onPause();
    });
    this.canvas.addEventListener("mousemove", (e: MouseEvent) => {
      this.mouseEvent = e;
      const { y1, y2 } = this.#controlsCoordinates();
      if (this.mouse.y <= y1 || this.mouse.y >= y2) return;
      this.showControls();
    });
  }

  /** @ignore */
  #removeListeners() {
    this.#currentScene.video.addEventListener("ended", () => {
      this.#onEnded();
    });
  }

  /** @ignore */
  #onPlay() {
    this.canvas.dataset.videoStatus = "playing";
  }

  /** @ignore */
  #onPause() {
    this.canvas.dataset.videoStatus = "paused";
  }

  /** @ignore */
  #onEnded() {
    this.canvas.dataset.videoStatus = "ended";
    this.#currentScene.video.currentTime = this.#currentScene.loopFrom;
    this.#currentScene.video.play();
  }

  /** @ignore */
  #drawVideo() {
    clearInterval(this.videoInterval);
    this.videoInterval = window.setInterval(() => {
      this.stage.drawImage(
        this.#currentScene.video,
        0,
        0,
        this.width,
        this.height
      );
      if (this.controls) this.#drawButtons();
      this.canvas.dataset.currentTime =
        this.currentScene.video.currentTime.toString();
      this.hideControls();
    }, 1000 / this.fps);
  }

  /** @ignore */
  #drawButtons() {
    for (const id in this.buttons) {
      this.stage.save();
      const button = this.buttons[id];
      if (!button.image) return;
      const x = (button.coordinates.x ) * this.width;
      const y = (button.coordinates.y ) * this.height;
      const width = (button.coordinates.width ) * this.width;
      const height = (button.coordinates.height ) * this.height;
      this.stage.drawImage(button.image, x, y, width, height);
    }
  }

  showControls() {
    this.controls = true;
    this, (this.#lastMouseMove = Date.now());
  }

  hideControls() {
    if (Date.now() - this.#lastMouseMove > this.controlsTimeout)
      this.controls = false;
  }

  /** @ignore */
  #onMouseDown(e: MouseEvent) {
    this.mouseEvent = e;
    console.log("x:" + this.mouse.x + "y:" + this.mouse.y);
    this.#currentScene =
      this.#currentScene || this.scenes[this.#initialSceneId];
    if (this.#currentScene.video.currentTime >= this.#currentScene.loopFrom)
      this.#detectClickMap();
    else if (
      !this.#detectButton() &&
      this.#currentScene.video.paused &&
      !this.#currentScene.video.ended
    )
      this.playScene(this.#currentScene);
    else this.showControls();
  }

  /** @ignore */
  #detectClickMap() {
    const clickMaps = this.scenes[this.#currentScene.id].clickMaps;
    for (const id in clickMaps) {
      if (
        this.mouse.x >= clickMaps[id].coordinates.leftTop.x &&
        this.mouse.x <= clickMaps[id].coordinates.rightBottom.x &&
        this.mouse.y >= clickMaps[id].coordinates.leftTop.y &&
        this.mouse.y <= clickMaps[id].coordinates.rightBottom.y
      ) {
        console.log("ClickMap: " + id + " detected");
        this.playScene(this.scenes[id]);
        return true;
      }
    }
    return false;
  }

  /** @ignore */
  #detectButton() {
    if (!this.controls) return;
    for (const id in this.buttons) {
      const button = this.buttons[id];
      if (
        this.mouse.x >= button.coordinates.x &&
        this.mouse.x <= button.coordinates.x + button.coordinates.width &&
        this.mouse.y >= button.coordinates.y &&
        this.mouse.y <= button.coordinates.y + button.coordinates.height
      ) {
        console.log("Button: " + button.action + " detected");
        switch (button.action) {
          case "play":
            this.#currentScene.video.play();
            break;
          case "pause":
            this.#currentScene.video.pause();
            break;
          case "replay":
            this.#currentScene.video.currentTime = 0;
            break;
          case "restart":
            this.playScene(this.scenes[this.#initialSceneId]);
            break;
        }
        return true;
      }
    }
    console.log("check for button");
    return false;
  }

  /** @ignore */
  #controlsCoordinates = () => {
    let y1 = this.buttons.play.coordinates.y;
    let y2 =
      this.buttons.play.coordinates.y + this.buttons.play.coordinates.height;
    for (const id in this.buttons) {
      const button = this.buttons[id];
      if (button.coordinates.y < y1) y1 = button.coordinates.y;
      if (
        this.buttons[id].coordinates.y + this.buttons[id].coordinates.height >
        y2
      )
        y2 =
          this.buttons[id].coordinates.y + this.buttons[id].coordinates.height;
    }
    return { y1: y1, y2: y2 };
  };
}

export class Scene {
  id!: string;
  video!: HTMLVideoElement;
  clickMaps: { [key: string]: ClickMap } = {};
  poster!: string;
  loopFrom!: HTMLVideoElement["currentTime"];

  constructor(src: HTMLVideoElement["src"]) {
    this.video = document.createElement("video");
    this.video.src = src;
    this.video.autoplay = false;
    this.video.controls = false;
    this.video.muted = false;
  }
  addClickMap(id: string) {
    this.clickMaps[id] = new ClickMap();
  }
}

export class ClickMap {
  coordinates = {
    leftTop: { x: 0, y: 0 },
    rightBottom: { x: 0, y: 0 },
  };

  #img!: HTMLImageElement;

  get width() {
    return this.coordinates.rightBottom.x - this.coordinates.leftTop.x;
  }

  get height() {
    return this.coordinates.rightBottom.y - this.coordinates.leftTop.y;
  }

  set image(src: HTMLImageElement["src"]) {
    this.#img = new Image();
    this.#img.src = src;
  }
}

export class Button {
  action!: "play" | "pause" | "replay" | "restart";
  src!: string;
  image?: HTMLImageElement;
  coordinates!: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  constructor(button: Button) {
    this.action = button.action;
    this.coordinates = button.coordinates;
    this.image = new Image();
    this.image.src = button.src;
  }
}
