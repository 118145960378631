import { Functions } from "./functions";

export default class extends Functions {
  /** @ignore */
  #canvas!: HTMLCanvasElement;

  /** @ignore */
  #grid: { x: number; y: number }[] = [];

  /** @ignore */
  #pieces: { x: number; y: number }[] = [];

  /** @ignore */
  #stage!: CanvasRenderingContext2D;

  /** @ignore */
  #selectedPiece: { gridIndex: number; selected: boolean } = {
    gridIndex: 0,
    selected: false,
  };

  /** @ignore */
  #img!: HTMLImageElement;

  canvasId!: HTMLCanvasElement["id"];

  canvasContainerId!: HTMLElement["id"];

  puzzlePieces = {
    horizontal: 3,
    vertical: 5,
  };

  style: {
    lineColor: CanvasRenderingContext2D["strokeStyle"];
    lineWidth: CanvasRenderingContext2D["lineWidth"];
    selectedColor: CanvasRenderingContext2D["strokeStyle"];
    selectedLineWidth: CanvasRenderingContext2D["lineWidth"];
    height: string;
    width: string;
  } = {
    lineColor: "#000",
    lineWidth: 4,
    selectedColor: "#000",
    selectedLineWidth: 4,
    height: "100%",
    width: "auto",
  };

  set src(src: HTMLImageElement["src"]) {
    this.#img = new Image();
    this.#img.src = src;
  }

  puzzleCoordinates = {
    leftTop: { x: 0, y: 0 },
    rightBottom: { x: 0, y: 0 },
  };

  async run() {
    await this.waitForImage(this.#img);
    this.#setCoordinates();
    this.#initCanvas();
    this.#createGrid();
    this.#initPieces();
    this.shuffleArray(this.#pieces);
    this.#createPuzzle();
    this.canvas.dataset.grid = JSON.stringify(this.#grid);
    this.canvas.dataset.pieces = JSON.stringify(this.#pieces);
    this.canvas.dataset.totalPieces = [
      this.puzzlePieces.horizontal * this.puzzlePieces.vertical,
    ].toString();
    this.canvas.dataset.solved = false.toString();
  }

  /** @ignore */
  #setCoordinates() {
    // Sets coordinates if not set by user
    this.puzzleCoordinates.rightBottom.x =
      this.puzzleCoordinates.rightBottom.x || this.#img.naturalWidth;
    this.puzzleCoordinates.rightBottom.y =
      this.puzzleCoordinates.rightBottom.y || this.#img.naturalHeight;
  }

  /** @ignore */
  async #initCanvas() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById(this.canvasContainerId)!.innerHTML +=
      '<canvas id="' +
      this.canvasId +
      '" width="' +
      this.#img.naturalWidth +
      '" height="' +
      this.#img.naturalHeight +
      '" style="height: ' +
      this.style.height +
      "; width: " +
      this.style.width +
      ';"></canvas>';
    this.canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
    this.stage = this.canvas.getContext("2d") as CanvasRenderingContext2D;
    this.canvas.width = this.#img.naturalWidth;
    this.canvas.height = this.#img.naturalHeight;
    this.canvas.addEventListener("click", (e: MouseEvent) =>
      this.#onMouseDown(e)
    );
  }

  /**
   * This is not implemented yet. For future update
   */
  /* createTitle(title: string) {
        this.stage.fillStyle = this.style.selectedColor
        this.stage.globalAlpha = .4
        this.stage.fillRect(100, this.#img.naturalHeight - 40, this.#img.naturalWidth - 200, 40)
        this.stage.fillStyle = this.style.selectedColor
        this.stage.globalAlpha = this.style.opacity
        this.stage.textAlign = this.style.textAlign
        this.stage.textBaseline = this.style.textBaseline
        this.stage.font = this.style.font
        this.stage.fillText(title, this.#img.naturalWidth / 2, this.#img.naturalHeight - 20)
    } */

  /** @ignore */
  async #createPuzzle() {
    this.stage.clearRect(0, 0, this.#img.naturalWidth, this.#img.naturalHeight);
    this.stage.drawImage(
      this.#img,
      0,
      0,
      this.#img.naturalWidth,
      this.#img.naturalHeight
    );
    this.stage.save();
    for (let i = 0; i < this.#pieces.length; i++) {
      this.stage.drawImage(
        this.#img,
        this.#pieces[i].x,
        this.#pieces[i].y,
        this.#pieceWidth,
        this.#pieceHeight,
        this.#grid[i].x,
        this.#grid[i].y,
        this.#pieceWidth,
        this.#pieceHeight
      );
      this.stage.save();
    }
    this.#drawLines();
  }

  /** @ignore */
  #drawLines() {
    this.stage.lineWidth = this.style.lineWidth;
    this.stage.strokeStyle = this.style.lineColor;
    // Horizontal lines
    for (let i = 1; i < this.puzzlePieces.vertical; i++) {
      this.stage.beginPath();
      this.stage.moveTo(
        this.puzzleCoordinates.leftTop.x,
        this.#pieceHeight * i + this.puzzleCoordinates.leftTop.y
      );
      this.stage.lineTo(
        this.puzzleCoordinates.rightBottom.x,
        this.#pieceHeight * i + this.puzzleCoordinates.leftTop.y
      );
      this.stage.stroke();
    }
    // Vertical lines
    for (let i = 1; i < this.puzzlePieces.horizontal; i++) {
      this.stage.beginPath();
      this.stage.moveTo(
        this.#pieceWidth * i + this.puzzleCoordinates.leftTop.x,
        this.puzzleCoordinates.leftTop.y
      );
      this.stage.lineTo(
        this.#pieceWidth * i + this.puzzleCoordinates.leftTop.x,
        this.puzzleCoordinates.rightBottom.y
      );
      this.stage.stroke();
    }
    // Draw Square around the puzzle
    this.stage.strokeRect(
      this.puzzleCoordinates.leftTop.x + this.style.lineWidth / 2,
      this.puzzleCoordinates.leftTop.y + this.style.lineWidth / 2,
      this.puzzleCoordinates.rightBottom.x -
        this.puzzleCoordinates.leftTop.x -
        this.style.lineWidth,
      this.puzzleCoordinates.rightBottom.y -
        this.puzzleCoordinates.leftTop.y -
        this.style.lineWidth
    );
  }

  /** @ignore */
  #translateCanvasX(x: number): number {
    return (x * this.canvas.width) / this.canvas.clientWidth;
  }

  /** @ignore */
  #translateCanvasY(y: number): number {
    return (y * this.canvas.height) / this.canvas.clientHeight;
  }

  /** @ignore */
  #onMouseDown(e: MouseEvent) {
    const x: number = this.#translateCanvasX(e.offsetX);
    const y: number = this.#translateCanvasY(e.offsetY);
    if (
      x < this.puzzleCoordinates.leftTop.x ||
      x > this.puzzleCoordinates.rightBottom.x
    )
      return;
    if (
      y < this.puzzleCoordinates.leftTop.y ||
      y > this.puzzleCoordinates.rightBottom.y
    )
      return;
    if (this.#selectedPiece.selected) this.#swapPieces(x, y);
    else this.#selectPiece(x, y);
  }

  /** @ignore */
  #selectPiece(x: number, y: number) {
    this.#selectedPiece.gridIndex = this.#getGridIndex(x, y);
    this.stage.lineWidth = this.style.selectedLineWidth;
    this.stage.strokeStyle = this.style.selectedColor;
    this.stage.beginPath();
    this.stage.rect(
      this.#grid[this.#selectedPiece.gridIndex].x +
        this.style.selectedLineWidth / 2,
      this.#grid[this.#selectedPiece.gridIndex].y +
        this.style.selectedLineWidth / 2,
      this.#pieceWidth - this.style.selectedLineWidth,
      this.#pieceHeight - this.style.selectedLineWidth
    );
    this.stage.stroke();
    this.stage.restore();
    this.#selectedPiece.selected = true;
  }

  /** @ignore */
  #swapPieces(x: number, y: number) {
    const otherpiece = this.#getGridIndex(x, y);
    this.swapArray(this.#pieces, this.#selectedPiece.gridIndex, otherpiece);
    this.#createPuzzle();
    if (this.isEqual(this.#grid, this.#pieces)) {
      this.canvas.dataset.solved = true.toString();
      this.onSuccess();
    }
    this.#selectedPiece.selected = false;
    this.canvas.dataset.grid = JSON.stringify(this.#grid);
    this.canvas.dataset.pieces = JSON.stringify(this.#pieces);
  }

  /** @ignore */
  #getGridIndex(x: number, y: number): number {
    let result = -1;
    for (let i = 0; i < this.#grid.length; i++) {
      if (
        x < this.#grid[i].x ||
        x > this.#grid[i].x + this.#pieceWidth ||
        y < this.#grid[i].y ||
        y > this.#grid[i].y + this.#pieceHeight
      ) {
        // No key
      } else {
        result = i;
      }
    }
    return result;
  }

  /** @ignore */
  #createGrid() {
    let x = this.puzzleCoordinates.leftTop.x;
    let y = this.puzzleCoordinates.leftTop.y;
    while (
      this.#grid.length <
      this.puzzlePieces.horizontal * this.puzzlePieces.vertical
    ) {
      this.#grid.push({ x: x, y: y });
      if (x >= this.#pieceWidth * (this.puzzlePieces.horizontal - 1)) {
        x = this.puzzleCoordinates.leftTop.x;
        y += this.#pieceHeight;
      } else {
        x += this.#pieceWidth;
      }
    }
  }

  /** @ignore */
  #initPieces() {
    this.#pieces = [...this.#grid];
  }

  /** @ignore */
  get #pieceWidth(): number {
    return Math.ceil(
      ((this.puzzleCoordinates.rightBottom.x || this.#img.naturalWidth) -
        this.puzzleCoordinates.leftTop.x) /
        this.puzzlePieces.horizontal
    );
  }

  /** @ignore */
  get #pieceHeight(): number {
    return Math.ceil(
      ((this.puzzleCoordinates.rightBottom.y || this.#img.naturalHeight) -
        this.puzzleCoordinates.leftTop.y) /
        this.puzzlePieces.vertical
    );
  }
}
