import { Functions } from "./functions"

export interface orderitem {
  question: string | number
  answer: string | number
}

/**
 * ```js
 * const OG = new window.GameLib.OrderGame()
 * OG.parentId = "OrderGameParent"
 * OG.addItem({ question: '<i class="fas fa-car"></i>', answer: "Car" })
 * OG.addItem({ question: '<i class="fas fa-bicycle"></i>', answer: "Bike" })
 * OG.onSuccess = function () { alert("Winner!!!") }
 * OG.run()
 * ```
 */
export default class extends Functions {
  /** @ignore */
  #items: orderitem[] = []
  /** @ignore */
  #correctAnswers: orderitem["answer"][] = []
  /** @ignore */
  #playerAnswers: orderitem["answer"][] = []
  /** @ignore */
  #container!: HTMLInputElement

  set parentId(parentId: string) {
    this.#container = document.getElementById(parentId) as HTMLInputElement
  }
  get parentElement() {
    return this.#container
  }

  style:
    {
      justifyContent: HTMLElement["style"]["justifyContent"]
      alignItems: HTMLElement["style"]["alignItems"]
    }
    =
    {
      justifyContent: 'center',
      alignItems: 'center',
    }

  addItem(orderObj: orderitem) {
    this.#items.push(orderObj)
    this.#correctAnswers.push(orderObj.answer)
    this.#playerAnswers.push(orderObj.answer)
    this.shuffleArray(this.#playerAnswers)
  }

  run() {
    // Set alignment and other style options
    this.#container.style.display = "flex"
    this.#container.style.justifyContent = this.style.justifyContent
    this.#container.style.alignItems = this.style.alignItems
    // Append rows
    let rows = ""
    for (let i = 0; i < this.#items.length; i++) {
      rows +=
        `<div class="input-group input-group-lg">
          <div class="input-group-prepend">
            <span class="input-group-text" id="1">${this.#items[i].question}</span>
          </div>
          <input type="text" class="form-control" value="${this.#playerAnswers[i]}" readonly>
          <div class="input-group-append">
            <button  id="OrderGameUp${i}" class="btn btn-info order-game-up"><i class="fas fa-chevron-down"></i></button>
            <button  id="OrderGameDown${i}" class="btn btn-info order-game-down"><i class="fas fa-chevron-up"></i></button>
            </br
          </div>
        </div>`

    }
    this.#container.innerHTML = rows
    document.querySelectorAll('.order-game-up').forEach(el => {
      el.addEventListener('click', (e: any) => this.#moveUp(e))
    });
    document.querySelectorAll('.order-game-down').forEach(el => {
      el.addEventListener('click', (e: any) => this.#moveDown(e))
    });
  }

  /** @ignore */
  #moveUp(e: any) {
    const index: number = e.currentTarget.id.replace(/[^0-9]/g, '');
    const array: orderitem["answer"][] = this.#playerAnswers
    this.moveUp(array, index).then(() => {
      this.run()
      if (this.isEqual(this.#correctAnswers, this.#playerAnswers)) this.onSuccess()
    })
  }

  /** @ignore */
  #moveDown(e: any) {
    const index: number = e.currentTarget.id.replace(/[^0-9]/g, '');
    const array: orderitem["answer"][] = this.#playerAnswers
    this.moveDown(array, index).then(() => {
      this.run()
      if (this.isEqual(this.#correctAnswers, this.#playerAnswers)) this.onSuccess()
    })
  }
}
