/* eslint-disable @typescript-eslint/no-empty-function */
import * as _ from "lodash";
export abstract class Functions {
  containerId: HTMLElement["id"] = "CanvasContainer";
  canvasId: HTMLCanvasElement["id"] = "Canvas";
  width = 1920;
  height = 1080;
  mouseEvent!: MouseEvent;

  canvas!: HTMLCanvasElement;

  stage!: CanvasRenderingContext2D;

  /**
   * Returns the relative position. So center = {x: .5, y: .5}
   */
  get mouse() {
    const x =
      (this.mouseEvent.offsetX * this.width) /
      this.canvas.clientWidth /
      this.width;
    const y =
      (this.mouseEvent.offsetY * this.height) /
      this.canvas.clientHeight /
      this.height;
    return { x: x, y: y };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess = (): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFail = (): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onWrong = (): void => {};

  isEqual(a: unknown, b: unknown): boolean {
    return _.isEqual(a, b);
  }

  shuffleArray(array: unknown[]) {
    if (array.length <= 1) return;
    const init = [...array];
    while (this.isEqual(init, array) == true)
      for (
        let j: number, x, i = array.length;
        i;
        j = Math.floor(Math.random() * i),
          x = array[--i],
          array[i] = array[j],
          array[j] = x
      );
  }

  swapArray(array: unknown[], indexA: number, indexB: number) {
    [array[indexA], array[indexB]] = [array[indexB], array[indexA]];
  }

  moveUp(array: unknown[], index: number) {
    return new Promise<unknown[]>((resolve) => {
      const target: number = +index + 1;
      // Check if target exists in array
      if (target >= array.length) return;
      [array[index], array[target]] = [array[target], array[index]];
      resolve(array);
    });
  }

  moveDown(array: unknown[], index: number) {
    return new Promise<unknown[]>((resolve) => {
      const target: number = +index - 1;
      // Check if target exists in array
      if (target < 0) return;
      [array[index], array[target]] = [array[target], array[index]];
      resolve(array);
    });
  }

  async waitForImage(image: HTMLImageElement) {
    if (!this.imageExists(image)) return;
    return new Promise<boolean>((resolve) => {
      image.addEventListener('load', () => resolve(true))
      image.addEventListener('error', () => resolve(false))
    });
  }

  imageExists(image: HTMLImageElement) {
    if (image === undefined) {
      return false;
    } else return true;
  }

  percentageToPixels(percentage: number) {
    return (percentage / 100) * this.width;
  }

  pixelsToPercentage(pixels: number) {
    return (pixels / this.width) * 100;
  }
}
